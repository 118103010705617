import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectCenters } from 'redux/selectors/centers';

const mapStateToProps = (state) => ({
  centers: selectCenters(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({

  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);

