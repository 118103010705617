import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';



import AquaoLogin from 'components/aquao-login/';
import useSetCenterCookie from 'hooks/use-set-center-cookie';
import { getCenterName } from 'utils/location';


const aquaoLogin = (props) => {
  const [centerId, setCenterId] = useState('');
  const centerName = getCenterName(props.location);
  const token = props.location.search.split("=")[1];

  useEffect(() => {
    setCenterId(`centre-${centerName}`);
  }, [centerName]);

  useSetCenterCookie(centerId);


  if (!centerId) {
    return null;
  }

  return (
    <Router path="/">
      <AquaoLogin centerId={centerId} path="/aquao-login" token={token} />
    </Router>
  );
};

export default aquaoLogin;
